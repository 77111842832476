import { faArrowAltCircleLeft, faArrowAltCircleRight, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { Favorite, Share, Visibility } from '@material-ui/icons'
import Link from 'components/Link'
import SEO from 'components/SEO'
import SessionContext from 'components/session/SessionContext'
import { dateFormatter, fetchFromAPI, fetchParallel, getTranslationContent, handleRouter, isArray } from 'functions'
import Image from 'next/image'
import Router from 'next/router'
import { useContext, useEffect } from 'react'
import useStyles from 'styles/Home.Style'
import useTranslation from 'translations/hooks/useTranslation'

export default function Home({ news, events, announcements }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const regex = /(style|class)=["']+(.*?)["']+/gm;

  let { session: { lang } } = useContext(SessionContext);
  let isAr = lang === 'ar';
  useEffect(() => {
    if (!Router?.query?.lang) handleRouter({ href: '/', as: '/' })
  }, [])

  // const pictures = ["/banner.png", "/banner2.jpg", "/banner3.jpg"];
  // const [indexpic, setIndexPic] = useState(0)

  // function showSlides() {
  //   let newIndex = indexpic + 1;
  //   if (newIndex > pictures.length - 1) newIndex = 0
  //   setIndexPic(newIndex)
  // }

  // setTimeout(showSlides, 2000);


  return (
    <Box display='flex' flexDirection='column' width={'100%'}>
      <SEO
        title={t('homeTitle').replace('<br/>', '')}
        description={t('homepageDescription')}
      />
      {/* <div className={classes.slideshowcontainer}>
        {pictures.map((p, i) => (
          <div
            key={i}
            className={classes.mySlidesfade}
            style={{
              display: i == indexpic ? 'block' : 'none'
            }}
          >
            <div className={classes.numbertext}>{`${i + 1} / ${pictures.length}`}</div>
            <Image
              src={p}
              alt='Banner'
              layout='responsive'
              width={828}
              height={196}
            />
          </div>
        ))}
      </div> */}
      <Image
        src={'/banner.png'}
        alt='Banner'
        layout='responsive'
        width={828}
        height={196}
      />
      <Box>
        <Box className={isAr ? classes.subtitleRight : classes.subtitleLeft}>
          {t('latestFeeds')}
        </Box>
      </Box>
      <Box className={classes.latestNewsContainer}>
        <Box className={classes.shape}>
          <Image
            src='/Shape.png'
            alt='shape'
            layout='responsive'
            width={80}
            height={300}
          />
        </Box>
        <Box className={isAr ? classes.allNewsRight : classes.allNewsLeft}>
          {news && news.news && isArray(news.news) && news.news.map(newsItem => (
            <Box className={classes.newsContainer} key={newsItem._id}>
              <Box className={isAr ? classes.newsBodyRight : classes.newsBodyLeft}>
                <Box display='flex' flexDirection='column' alignItems='flex-end'>
                  <Box className={classes.newsTitle + ' partTitle'} style={{ textAlign: isAr ? 'right' : 'left' }}>
                    {getTranslationContent(newsItem, 'title', lang)}
                  </Box>
                  <Box className={classes.newsDescription + ' partText'} style={{ textAlign: isAr ? 'right' : 'left' }}>
                    <Box
                      dangerouslySetInnerHTML={{ __html: getTranslationContent(newsItem, 'body', lang)?.replace(regex, '') }}
                    />
                  </Box>
                </Box>
                <Link href='/news/article/[slug]' as={`/news/article/${newsItem?.slug}`}>
                  {isAr ?
                    <FontAwesomeIcon icon={faArrowAltCircleLeft} className={classes.newsIcon} />
                    :
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className={classes.newsIcon} />
                  }
                </Link>
              </Box>
              <Box className={isAr ? classes.newsImageRight : classes.newsImageLeft}>
                <Box
                  style={{
                    width: '100%',
                    height: 'fit-content',
                    backgroundImage: `url(/placeholder.jpg)`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    zIndex: 0,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Box className={classes.newImage}>
                    <Image
                      src={newsItem.image ? `${newsItem.image}` : '/placeholder.jpg'}
                      alt='News Image'
                      layout='responsive'
                      height={500}
                      width={828}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      <Box>
        <Box className={isAr ? classes.subtitleRight : classes.subtitleLeft}>
          {t('upcomingEvents')}
        </Box>
      </Box>
      <Box className={isAr ? classes.upcomingEventsContainerRight : classes.upcomingEventsContainerLeft}>
        <Box className={isAr ? classes.eventsContainerRight : classes.eventsContainerLeft}>
          {events && isArray(events) && events.map(event => (
            <Link href='/events/details/[slug]' as={`/events/details/${event?.slug}`} key={event._id}>
              <Box className={classes.styleEvent}>
                <Box className={classes.eventBox}>
                  <Box
                    style={{
                      width: '100%',
                      height: 'fit-content',
                      backgroundImage: `url(./placeholder.jpg)`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      zIndex: 0,
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                  >
                    <Box className={classes.eventImage}>
                      <Image
                        src={event.image ? `${event.image}` : '/placeholder.jpg'}
                        alt='Event Image'
                        layout='responsive'
                        height={350}
                        width={500}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.eventDate}>
                    {dateFormatter(event.start, 'D MMMM, YYYY', isAr)}
                  </Box>
                  <Box className={classes.eventDetails}>
                    <Box className={classes.eventText}>
                      <Box className={classes.eventTitle + ' partTitle'}>
                        {getTranslationContent(event, 'title', lang)}
                      </Box>
                      <Box>
                        <Box className={classes.eventDescription + ' partTextEvent'}
                          dangerouslySetInnerHTML={{ __html: getTranslationContent(event, 'description', lang).replace(regex, '') }}
                        />
                      </Box>
                    </Box>
                    <Box className={classes.interactionsSummary}>
                      <Box className={classes.interactionsCount}>
                        <Box style={{ fontSize: 13, marginLeft: 3 }}>
                          {t('shares')}
                        </Box>
                        <Share style={{ fontSize: 14 }} />
                      </Box>
                      <Box className={classes.interactionsCount}>
                        <Box style={{ fontSize: 13, marginLeft: 3 }}>
                          {event?.interactionsSummary?.likes} {t('likes')}
                        </Box>
                        <Favorite style={{ fontSize: 14 }} />
                      </Box>
                      <Box className={classes.interactionsCount}>
                        <Box style={{ fontSize: 13, marginLeft: 3 }}>
                          {event?.interactionsSummary?.views} {t('views')}
                        </Box>
                        <Visibility style={{ fontSize: 14 }} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.yellowLine} />
              </Box>
            </Link>
          ))}
        </Box>
      </Box>

      <Box>
        <Box className={isAr ? classes.subtitleRight : classes.subtitleLeft}>
          {t('Announcements')}
        </Box>
      </Box>
      <Box className={classes.anouncementsContainer}>
        <Box className={classes.latestNews}>
          {isArray(announcements?.news) && announcements.news.map(announcement => (
            <Link key={announcement._id} href='/news/article/[slug]' as={`/news/article/${announcement?.slug}`}>
              <Box className={classes.latestNewsDiv} sx={{ borderRadius: 3 }}>
                <Box className={classes.latestNewsTitle}>
                  {isAr ?
                    <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: 23, color: '#F5B750', marginLeft: 15, transform: 'rotateY(180deg)' }} />
                    :
                    <FontAwesomeIcon icon={faBullhorn} style={{ fontSize: 23, marginRight: 15, color: '#F5B750' }} />
                  }
                  {getTranslationContent(announcement, 'title', lang)}
                </Box>
                <Box className={classes.latestNewsDate}>
                  {dateFormatter(announcement?.createdAt, 'D MMM, YYYY', isAr)}
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export async function getServerSideProps(context) {
  let { lang } = context.query;
  let requests = [
    { key: 'news', apiName: 'news', reqBody: { limit: 3, page: 1, stripHtml: true, category: 'activities', lang } },
    { key: 'events', apiName: 'events', reqBody: { limit: 3, page: 1, stripHtml: true, lang } },
    { key: 'announcements', apiName: 'news', reqBody: { limit: 5, page: 1, category: 'announcements', lang } },
  ]
  let response = {};
  (context.req) ?
    response = await fetchParallel(requests) :
    response = await fetchFromAPI({ api: 'home', reqBody: requests });
  const {
    events: { response: events },
    news: { response: news },
    announcements: { response: announcements }
  } = response;
  return { props: { news, events, announcements } }
}

// export async function getStaticProps({ params }) {
//   let { lang = 'en' } = params;
//   let requests = [
//     { key: 'news', apiName: 'news', reqBody: { limit: 3, page: 1, stripHtml: true, category: 'activities', lang } },
//     { key: 'events', apiName: 'events', reqBody: { limit: 3, page: 1, stripHtml: true, lang } },
//     { key: 'announcements', apiName: 'news', reqBody: { limit: 5, page: 1, category: 'announcements', lang } },
//   ];
//   let response = await fetchParallel(requests);
//   const {
//     events: { response: events },
//     news: { response: news },
//     announcements: { response: announcements }
//   } = response;
//   return { props: { news, events, announcements } }
// }

// export async function getStaticPaths() {
//   return {
//     paths: [
//       { params: { lang: 'en', lang: 'ar', lang: 'fr' } }
//     ],
//     fallback: 'blocking',
//   }
// }