import defaultSEO from 'data/seo.json'
import { NextSeo } from 'next-seo'
import { Fragment, useContext } from 'react'
import SessionContext from './session/SessionContext'

export default function SEO(props) {
    let { session: { lang = 'en' } } = useContext(SessionContext);

    let seoProps = { ...props };

    if (props?.title) {
        if (!props?.og_title)
            seoProps['og_title'] = props.title;
        if (!props?.twitter_title)
            seoProps['twitter_title'] = props.title;
    }
    if (props?.description && !props?.og_description)
        seoProps['og_description'] = props.description;

    let seo = {
        ...defaultSEO[lang],
        ...seoProps
    };

    function separateSEOFields(seo) {
        let openGraph = {}, twitter = {}, root = {};
        for (let property in seo) {
            if (property.startsWith('og_')) {
                openGraph[property.replace('og_', '')] = seo[property]
            } else if (property.startsWith('twitter')) {
                twitter[property.replace('twitter_', '')] = seo[property]
            } else {
                root[property] = seo[property]
            }
        }
        return { openGraph, twitter, root }
    }

    let { twitter, openGraph, root } = separateSEOFields(seo);

    return (
        <Fragment>
            <NextSeo
                {...root}
                openGraph={openGraph}
                twitter={twitter}
                additionalMetaTags={[
                    { name: 'keywords', content: (root?.keywords || []).join(',') },
                    { name: 'og:image', content: openGraph?.image },
                    { property: 'og:image', content: openGraph?.image }
                ]}
            />
        </Fragment>
    )
}