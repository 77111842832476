import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    latestNewsContainer: {
        display: 'flex',
        width: '70%',
        backgroundColor: '#E4E4E4',
        padding: '2% 0px',
        [theme.breakpoints.down('769')]: {
            width: '90%',
            height: 'auto',
        }
    },
    anouncementsContainer: {
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        marginBottom: '5%',
        padding: '2% 1%',
        [theme.breakpoints.down('769')]: {
            width: '100%',
            marginBottom: '10%',

        },
    },
    latestNews: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '2% 0',
        alignItems: 'center',
        alignSelf: 'center',
    },
    latestNewsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: '0px 3px 2px rgb(177, 174, 174)',
        cursor: 'pointer',
        padding: '1.5%',
        margin: '1% auto',
        width: '80%',
        minHeight: 60,
        [theme.breakpoints.down('1100')]: {
            width: '100%',
            height: 'auto',
            margin: '2% auto',
        },
        [theme.breakpoints.down('500')]: {
            flexDirection: 'column',
        }
    },
    latestNewsBlock: {
        width: '90%',
        minHeight: 350,
        backgroundColor: 'white',
        boxShadow: '0px 3px 2px rgb(177, 174, 174)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '2%',
    },
    latestNewsTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#344450',
        display: 'flex',
        alignItems: 'center',
        width: '80%',
        [theme.breakpoints.down('500')]: {
            width: '100%',
            fontSize: 16,
            justifyContent: 'center'
        }
    },
    latestNewsDate: {
        color: '#344450',
        fontSize: 16,
        fontWeight: '500',
        width: '20%',
        display: 'flex',
        justifyContent: 'flex-end',

        [theme.breakpoints.down('500')]: {
            width: '100%',
            fontSize: 14,
            justifyContent: 'center'
        }
    },
    shape: {
        width: '40%',
        [theme.breakpoints.down('769')]: {
            display: 'none'
        }
    },
    subtitleLeft: {
        borderLeft: '7px solid #F5B750',
        borderLeftStyle: 'solid',
        borderLeftWidth: 7,
        fontSize: 26,
        color: '#344450',
        fontWeight: 'bold',
        padding: '2% 3%',
        margin: '3% 0 3% 7%',
        [theme.breakpoints.down('1100')]: {
            borderLeft: '5px solid #F5B750',
            fontSize: 24,
        },
        [theme.breakpoints.down('769')]: {
            borderLeft: '4px solid #F5B750',
            fontSize: 22,
            margin: '7% 0 7% 7%',

        }
    },
    subtitleRight: {
        borderRight: '7px solid #F5B750',
        fontSize: 26,
        color: '#344450',
        fontWeight: 'bold',
        padding: '2% 3%',
        margin: '3% 7% 3% 0',
        [theme.breakpoints.down('1100')]: {
            borderRight: '5px solid #F5B750',
            fontSize: 24,
        },
        [theme.breakpoints.down('769')]: {
            borderRight: '3px solid #F5B750',
            fontSize: 22,
        }
    },
    allNewsLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
        [theme.breakpoints.down('769')]: {
            marginTop: 50
        }
    },
    allNewsRight: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        [theme.breakpoints.down('769')]: {
            marginTop: 50
        }
    },
    newsContainer: {
        width: '100%',
        [theme.breakpoints.up('769')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '3% 0px',
        },
        [theme.breakpoints.down('769')]: {
            display: 'flex',
            flexDirection: 'column-reverse',
            alignItems: 'center',
        }
    },
    newsBodyLeft: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: '#FFFFFF',
        color: '#344450',
        width: '45%',
        padding: '2%',
        minHeight: 200,
        maxHeight: 200,
        [theme.breakpoints.up('769')]: {
            position: 'absolute',
        },
        [theme.breakpoints.down('769')]: {
            width: '89%',
            marginLeft: 'auto',
            position: 'relative',
            bottom: '20%'
        },
        zIndex: 1
    },
    newsBodyRight: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#FFFFFF',
        color: '#344450',
        width: '45%',
        padding: '2%',
        minHeight: 200,
        maxHeight: 200,
        [theme.breakpoints.up('769')]: {
            position: 'absolute',
        },
        [theme.breakpoints.down('769')]: {
            width: '89%',
            marginRight: 'auto',
            position: 'relative',
            bottom: '20%'
        },
        zIndex: 1
    },
    newsTitle: {
        width: '100%',
        fontSize: 24,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('1100')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('769')]: {
            fontSize: 16,
        }
    },
    newsDescription: {
        lineHeight: '1.5em',
        fontSize: 18,
        paddingTop: 0,
        [theme.breakpoints.down('1100')]: {
            fontSize: 16,
        },
        [theme.breakpoints.down('769')]: {
            fontSize: 14,
        }
    },
    newsImageLeft: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('769')]: {
            width: '70%',
            position: 'relative',
            left: '70%',
        },
        [theme.breakpoints.down('769')]: {
            width: '100%',
            marginLeft: '8%',
            paddingLeft: '3%'
        },
        height: 'auto',
        maxWidth: '100%',
        zIndex: 0
    },
    newsImageRight: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('769')]: {
            width: '70%',
            position: 'relative',
            right: '70%',
        },
        [theme.breakpoints.down('769')]: {
            width: '100%',
            marginRight: '8%',
            paddingRight: '3%'
        },
        height: 'auto',
        maxWidth: '100%',
        zIndex: 0
    },
    newsIcon: {
        margin: '4%',
        cursor: 'pointer',
        '&:hover': {
            color: '#F5B750'
        },
        fontSize: 45,
        [theme.breakpoints.down('1100')]: {
            fontSize: 36,
        },
        [theme.breakpoints.down('769')]: {
            fontSize: 30,
        }
    },
    upcomingEventsContainerLeft: {
        backgroundColor: '#E4E4E4',
        padding: '5% 0',
        marginLeft: 'auto',
        marginBottom: '4%',
        height: 'auto',
        [theme.breakpoints.up('769')]: {
            width: '75%',
        },
        [theme.breakpoints.down('769')]: {
            width: '80%',
        },
        [theme.breakpoints.up('1000')]: {
            width: '75%',
        },
        [theme.breakpoints.up('2000')]: {
            width: '80%',
        },
        [theme.breakpoints.between('1024', '1400')]: {
            width: '75%',
        }
    },
    upcomingEventsContainerRight: {
        backgroundColor: '#E4E4E4',
        padding: '5% 0',
        marginRight: 'auto',
        marginBottom: '4%',
        height: 'auto',
        [theme.breakpoints.up('769')]: {
            width: '75%',
        },
        [theme.breakpoints.down('769')]: {
            width: '80%',
        },
        [theme.breakpoints.up('1000')]: {
            width: '75%',
        },
        [theme.breakpoints.up('2000')]: {
            width: '80%',
        },
        [theme.breakpoints.between('1100', '1400')]: {
            width: '75%',
        }
    },
    eventsContainerLeft: {
        [theme.breakpoints.up('1000')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            position: 'relative',
            right: '12%',
        },
        [theme.breakpoints.down('1000')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
            right: '12.5%',
            marginTop: '7%',
        }
    },
    eventsContainerRight: {
        [theme.breakpoints.up('1000')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            position: 'relative',
            left: '12%',
        },
        [theme.breakpoints.down('1000')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
            left: '12.5%',
            marginTop: '7%',
        }
    },
    eventBox: {
        width: '100%',
        minHeight: 500,
        cursor: 'pointer',
        backgroundColor: '#FFFFFF',
        padding: 10,
        paddingBottom: 20,
        boxShadow: '0px 3px 3px rgb(177, 174, 174)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        alignContent: 'center',
    },
    styleEvent: {
        width: '31.5%',
        [theme.breakpoints.down('1100')]: {
            width: '45%',
        },
        [theme.breakpoints.down('600')]: {
            width: '90%',
        },
        minWidth: 240,
        marginBottom: 15,
    },
    eventDate: {
        color: '#344450',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        fontSize: 16,
        marginTop: '2%'
    },
    eventImage: {
        height: 'auto',
        width: '100%',
    },
    newImage: {
        height: 'auto',
        width: '100%',

    },
    eventDetails: {
        height: 'auto',
        minHeight: 250,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('769')]: {
            width: '100%',
        },
    },
    eventText: {
        textAlign: 'center',
        padding: '3%',
        width: '100%',
        margin: 'auto'
    },
    eventTitle: {
        lineHeight: '1.2em',
        fontSize: 17,
        color: '#344450',
        marginBottom: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down('769')]: {
            width: '95%',
            paddingTop: 0
        },
    },
    eventDescription: {
        lineHeight: '1.5em',
        textAlign: 'center',
        fontSize: 18,
        maxWidth: '100%',
        paddingTop: 0,
        color: '#344450',
        '& p': {
            margin: 0
        },
        [theme.breakpoints.down('769')]: {
            fontSize: 14,
            width: '90%',
            paddingTop: 0
        },
    },
    interactionsSummary: {
        color: '#344450',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    interactionsCount: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 5px',
        justifyContent: 'flex-end'
    },
    yellowLine: {
        height: 8,
        width: '80%',
        backgroundColor: '#F5B750',
        margin: '0 auto',
        marginBottom: '7%',
        boxShadow: 'inset 0px 3px 3px rgb(177, 174, 174)',
    },


    /* Slideshow container */
    slideshowcontainer: {
        maxWidth: "100%",
        height:'auto',
        position: "relative",
        margin: "auto",
    },

    /* Caption text */
    text: {
        color: "#f2f2f2",
        fontSize: 15,
        padding: '8px 12px',
        position: 'absolute',
        bottom: '8px',
        width: '100%',
        textAlign: 'center',
    },
    /* Number text (1/3 etc) */
    numbertext: {
        color: "#f2f2f2",
        fontSize: '12px',
        padding: '8px 12px',
        position: 'absolute',
        top: 0
    },

    /* The dots/bullets/indicators */
    dot: {
        height: '15px',
        width: '15px',
        margin: '0 2px',
        backgroundColor: '#bbb',
        borderRadius: '50%',
        display: 'inline-block',
        transition: 'backgroundColor 0.6s ease'
    },

    active: {
        backgroundColor: '#717171'
    },

    /* Fading animation */
    fade: {
        '-webkit-animation-name': 'fade',
        '-webkit-animation-duration': '1.5s',
        'animation-name': 'fade',
        'animation-duration': '1.5s',
    },

    '@-webkit-keyframes fade': {
        from: { opacity: .4 },
        to: { opacity: 1 }
    },

    '@keyframes fade': {
        from: { opacity: .4 },
        to: { opacity: 1 }
    },

// /* On smaller screens, decrease text size */
// @media only screen and(max - width: 300px) {
//   .text { font - size: 11px }
// }
}));

export default useStyles